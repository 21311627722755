<div class="d-flex gap-1 align-items-center" *ngIf="modifiedBy">
  <div class="avatar-round">
    <img *ngIf="modifiedBy.image" [ngSrc]="modifiedBy.image" width="30" height="30">
    <span *ngIf="!modifiedBy.image" class="ts-icon ts-icon-users-profile"></span>
  </div>
  <div class="d-flex flex-column justify-content-between gap-1">
    <div class="ts-caption-strong">{{modifiedBy.name}}</div>
    <div class="ts-caption">
      <span class="ts-text-tertiary">Когда: </span>
      <span>{{changeableRecord.modifiedAt! | date :  "dd.MM.YYYY HH:mm"}}</span>
    </div>
  </div>
</div>
