<div class="ts-table-header-cell justify-content-between">
  <div class="ts-table-header-cell__label">
    {{tableHeader.label}}
  </div>
  <ts-dropdown [content]="ddContent">
    <div class="rotate-90">
      <i class="ts-icon ts-icon-settings-more cursor-pointer"></i>
    </div>
  </ts-dropdown>

</div>
<ng-template #ddContent>
  <ts-scroll class="scroll-wrapper">
    <ng-container *ngIf="prices$|async as prices">
      <button *ngFor="let price of prices" type="button" class="dropdown-item" dropdownOption
              (click)="selectPrice(price)">
        {{price.name}}
      </button>
    </ng-container>
  </ts-scroll>
</ng-template>
