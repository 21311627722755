import { EntityNamePipe } from './entity.pipe';
import { NgModule } from '@angular/core';

import { FullnameWithInitialPipe } from './fullname-with-initial.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import {
  CurrencyFormatModule,
  CurrencyFormatPipe,
} from '@topseller/ui/pipes/currency-format';
import { ValueUnitModule, ValueUnitPipe } from '@topseller/ui/pipes/value-unit';
import { HubEntityNamePipe } from './hub-entity-name.pipe';

@NgModule({
  declarations: [
    FullnameWithInitialPipe,
    SafeHtmlPipe,
    EntityNamePipe,
    HubEntityNamePipe,
  ],
  exports: [
    FullnameWithInitialPipe,
    SafeHtmlPipe,
    EntityNamePipe,
    CurrencyFormatPipe,
    ValueUnitPipe,
    HubEntityNamePipe
  ],
  imports: [ValueUnitModule, CurrencyFormatModule],
})
export class PipesModule {}
