import {
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableHeaderCellComponent } from '@topseller/ui/tables';
import { TsCommonModule } from '../common.module';
import { LoaderAnimatedModule } from '@topseller/ui/loader-animated';
import { TsScrollModule } from '@topseller/ui/scroll';
import { TableHeader } from '@topseller/ui';
import {
  TsTableModule,
  DataItemToTableValueMapper,
  TableCellTemplateDirective,
} from '../table';
import { BaseListComponent } from '../base-list';
import { TsDataEntity } from '@topseller/core';
import { TableHeaderCell2Component } from '../table-header-cell2/table-header-cell2.component';
import { TsTableRowDirective } from './table-row.directive';

@Component({
  selector: 'ts-items-table',
  standalone: true,
  imports: [
    CommonModule,
    TableHeaderCellComponent,
    TsCommonModule,
    LoaderAnimatedModule,
    TsScrollModule,
    TsTableModule,
    TableHeaderCell2Component,
    TsTableRowDirective,
  ],
  templateUrl: './items-table.component.html',
  styleUrls: ['./items-table.component.scss'],
})
export class ItemsTableComponent<T extends TsDataEntity> {
  @Output() openItem = new EventEmitter();
  @Input() tableHeaders: TableHeader[] = [];
  @Input() isSelectable = true; //можно ли выделять строки
  @Input() entityName?: string;
  @ContentChild(TemplateRef) cellContentTemplate!: TemplateRef<unknown>;
  @ContentChild('emptyContent', { static: true })
  emptyContentTemplate!: TemplateRef<unknown>;
  @Input() lastRowTemplate?: TemplateRef<unknown>;

  @ContentChildren(TableCellTemplateDirective)
  cells!: QueryList<TableCellTemplateDirective>;

  constructor(
    public host: BaseListComponent<T>,
    public dataItemToTableValueMapper: DataItemToTableValueMapper
  ) {}

  getCellDirectiveByKey(key: string): TableCellTemplateDirective | undefined {
    return this.cells.find((cell) => cell.tsTableCellTemplate === key);
  }
}
