import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {Product} from "../../../data";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {TsProductTypePipe} from "../../../pages/products-services/product-list/products/product-type.pipe";

@Component({
  imports: [CommonModule, TsProductTypePipe, NgOptimizedImage],
  selector: 'ts-product-name-cell',
  templateUrl: './product-name-cell.component.html',
  styleUrls: ['./product-name-cell.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductNameCellComponent {
  @Input() product!: Product;
  @Input() showButtonOpenProduct: boolean = false;
  @Output() openProduct = new EventEmitter<Product>();

  public openProductClick(): void {
    this.openProduct.emit(this.product);
  }
}
