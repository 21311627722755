import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DATA_LIST_HOST, DataListHost, IdentityMatcher, StringHandler, } from '@topseller/ui';
import { AbstractFormControl } from './abstract-form-control';
import { defaultIdentityMatcher, defaultStringHandler, getNativeFocused, } from '@topseller/ui/utils';
import { TsItem } from '../model/item';
import { BaseInputComponent } from "@topseller/ui/base-input";

@Component({
  selector: 'ts-multiselect',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DATA_LIST_HOST,
      useExisting: forwardRef(() => MultiSelectComponent),
    },
  ],
})
export class MultiSelectComponent<T>
  extends AbstractFormControl<readonly T[]>
  implements DataListHost<T> {
  @Input() placeholder = '';
  @Input() removable = false;

  @Input()
  public stringify: StringHandler<T> = defaultStringHandler;

  @Input()
  public identityMatcher: IdentityMatcher<T> = defaultIdentityMatcher;

  @Input() shortChipsDisplay = false; // отображать ли все выбранные чипсы или сокращенно

  @Output() added = new EventEmitter<T[]>();
  @Output() removed = new EventEmitter<T[]>();

  @Output()
  public readonly searchChange = new EventEmitter<string>();

  @Output()
  public readonly focusChanged = new EventEmitter<boolean>();

  public search = '';

  @ViewChild(BaseInputComponent, {static: true})
  public readonly textfield?: BaseInputComponent;

  private localIsVisible = false;

  constructor(
    @Self() @Optional() ngControl: NgControl,
    cdRef: ChangeDetectorRef,
    protected elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super(ngControl, cdRef);

    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get isVisible(): boolean {
    return this.localIsVisible;
  }

  public set isVisible(val: boolean) {
    if (this.disabled) {
      return;
    }

    this.localIsVisible = val;
  }

  public get computedValue(): readonly T[] {
    return this.value || [];
  }

  @HostListener('click')
  public onClick() {
    this.toggleDropdown();
  }

  public handleOption(option: T): void {
    if (this.disabled) {
      return;
    }
    let {value} = this;
    value = value.length ? value : [];

    const {identityMatcher} = this;
    const index = value.findIndex((item) => identityMatcher(item, option));

    this.updateSearch('');

    if (index === -1) {
      this.added.emit([...value, option]);
      this.updateValue([...value, option]);
    } else {
      this.removed.emit([
        ...value.filter((v) => (v as TsItem).id !== (option as TsItem).id),
      ]);
      this.updateValue(value.filter((_, i) => i !== index));
    }
  }

  public clear() {
    this.isVisible = false;
    this.updateSearch('');
    this.updateValue([]);
  }

  public onActiveZone(active: boolean) {
    if (!active && this.isVisible) {
      this.isVisible = false;
      this.updateSearch('');
      this.toggleFocusInput();
    }
  }

  public toggleDropdown() {
    if (!this.disabled) {
      this.updateOpen(!this.isVisible);
    }
  }

  public onInput(value: string) {
    this.updateSearch(value);
  }

  public updateOpen(open: boolean): void {
    this.isVisible = open;
    this.toggleFocusInput();
  }

  protected getFallbackValue(): readonly T[] {
    return [];
  }

  private updateSearch(value: string): void {
    this.search = value;
    this.searchChange.emit(value);
  }

  // поставить/снять фокус в инпут чтобы отработала подсветка бордера.
  private toggleFocusInput(): void {
    if (this.textfield) {
      const element = this.textfield.elementRef.nativeElement;
      if (this.isVisible) {
        this.renderer.addClass(element, 'focused');
      } else {
        this.renderer.removeClass(element, 'focused');
      }
    }
  }
}
