import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { selectFormFields, updateCustomFields } from '../../../store';
import {CustomField, entityNames, HubEntity} from '../../../data';
import { IdentityMatcher, StringHandler } from '../../../common';
import { CustomFieldsApiService } from '../../../data/api/custom-fields-api.service';
import {Subject, switchMap, take, takeUntil, tap} from 'rxjs';
import { ColumnSettings, ConfirmDialogComponent, Record, ROLES } from "@topseller/core";
import {ToggleButtonModel} from "@topseller/ui";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

export const CustomFieldTypesOptions = [
  { id: 'number', name: 'Число' },
  { id: 'text', name: 'Строка' },
  { id: 'boolean', name: 'Флажок' },
  { id: 'link', name: 'Ссылка' },
  { id: 'fractional', name: 'Дробное число' },
  { id: 'datetime', name: 'Дата' },
];

@Component({
  selector: 'ts-add-edit-custom-fields-modal',
  templateUrl: './add-edit-custom-fields-modal.component.html',
  styleUrls: ['./add-edit-custom-fields-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomFieldsModalComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  form: FormGroup;
  editItemRole = ROLES.ROLE_CUSTOM_FIELD_EDIT;

  menu: ToggleButtonModel[] = [];

  selectedEntity$ = new Subject<HubEntity>();
  selectedEntity: HubEntity;

  get fieldsFormArray(): FormArray {
    return this.form.get('fields') as FormArray;
  }

  //массив который хранит индексы контролов с одинаковыми именами
  duplicates: number[] = [];
  customFieldTypesOptions = CustomFieldTypesOptions;
  public recordStringify: StringHandler<string> = (item) =>
    this.customFieldTypesOptions.find((x) => x.id == item)?.name ?? '';
  public recordIdentityMatcher: IdentityMatcher<Record> = (
    { id: previousId },
    { id: nextId }
  ) => previousId === nextId;

  constructor(
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService: ToastrService,
    protected store: Store,
    private customFieldsApiService: CustomFieldsApiService,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      fields: this.fb.array([], this.uniqueNameValidator()),
    });

    if (this.data.relatedEntities) {
      this.menu = this.data.relatedEntities.map((x: HubEntity) => {
        return {id: x, name: entityNames[x]}
      });
      this.menu.unshift({id: this.data.hubEntity, name: entityNames[this.data.hubEntity]!})
    }
    this.selectedEntity = this.data.hubEntity;

    this.selectedEntity$.pipe(
      takeUntil(this.destroy$),
      tap(entity => this.selectedEntity = entity),
      switchMap(entity => this.store.select(selectFormFields(entity)))
    ).subscribe((fields) => {
        this.fieldsFormArray.clear();
        fields?.forEach((field) =>
          this.fieldsFormArray.push(this.createControlsGroup(field))
        );
      }
    );

    this.selectedEntity$.next(this.data.hubEntity);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createControlsGroup(field: CustomField | null) {
    const group = this.fb.group({
      name: [field?.name, [Validators.required, Validators.pattern(/[\S]/)]],
      id: [field?.id],
      type: [field?.type, [Validators.required]],
      entity: this.selectedEntity,
      sort: [this.fieldsFormArray.length ? this.fieldsFormArray.length + 1 : 1],
    });
    return group;
  }

  private uniqueNameValidator(): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      const groupControls = (formArray as FormArray).controls;

      groupControls.forEach((currentGroup) => {
        const currentNameControl = currentGroup.get('name');

        const duplicated = groupControls.find((group) => {
          const currentControl = group.get('name');

          return (
            currentControl?.value &&
            currentControl !== currentNameControl &&
            currentControl?.value === currentNameControl?.value
          );
        });

        if (duplicated) {
          currentNameControl?.setErrors({ duplicated: true });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { duplicated = null, ...errors } =
            (currentNameControl?.errors || {}) as { duplicated: boolean };
          currentNameControl?.setErrors(
            Object.keys(errors).length ? errors : null
          );
        }
      });

      return null;
    };
  }

  public save(): void {
    //без этого не сбрасываются ошибки валидации дубликатов.
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      const formValue = this.fieldsFormArray.value;
      this.customFieldsApiService
        .batch(formValue)
        .pipe(take(1))
        .subscribe({
          next: ({ items }) => {
            this.toastrService.success('Дополнительные поля сохранены');
            this.store.dispatch(
              updateCustomFields({
                fields: items,
                entityName: this.selectedEntity,
              })
            );
            // закрываем модалку только если нет дополнительных вкладок
            if (this.menu.length === 0) {
              this.matDialogRef.close();
            }
            this.form.markAsPristine();
          },
          error: (err: any) => {
            this.toastrService.error(
              err?.errors?.length && err.errors[0].message
                ? err.errors[0].message
                : err?.message || 'Что-то пошло не так'
            );
          },
        });
    }
  }

  addField() {
    this.fieldsFormArray.push(this.createControlsGroup({
      id: '',
      name: '',
      type: 'number',
      entity: this.selectedEntity,
      value: ''
    }));
  }

  deleteField(index: any) {
    this.fieldsFormArray.removeAt(index);
  }

  close() {
    this.matDialogRef.close(false);
  }

  selectEntity(entity: HubEntity) {
    if (this.form.dirty) {
      const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
        ConfirmDialogComponent,
        {
          data: {
            title: 'Данные были изменены',
            content:
              'Если продолжить без сохранения, то все внесенные изменения будут утеряны',
            okBtn: 'Продолжить без сохранения',
          },
          width: '500px',
        }
      );

      dialogRef.afterClosed().pipe(
      ).subscribe((result) => {
        if (result) {
          this.form.markAsPristine();
          this.selectedEntity$.next(entity);
        }
      });
    } else {
      this.selectedEntity$.next(entity);
    }

  }

  public onDrop(event: CdkDragDrop<CustomField>) {
    moveItemInArray(this.fieldsFormArray.controls, event.previousIndex, event.currentIndex);
    this.fieldsFormArray.controls.forEach((control, index) => {
      control.get('sort')?.setValue(index + 1);
    });
  }

  public deleteItemRole(index: number){
    const item = this.fieldsFormArray.at(index).value;
    if (item.id){
      return ROLES.ROLE_CUSTOM_FIELD_DELETE;
    }else {
      return  '';
    }
  }
}
