<form [formGroup]="form" #formEl="ngForm" (ngSubmit)="save()" class="ts-modal-layout">
  <div mat-dialog-title>
    <div class="d-flex justify-content-between align-items-center"><span class="ts-subtitle">Настройка дополнительных полей</span>
      <span class="ts-icon ts-icon-actions-Close ts-text-icon-neutral-color text-2xl" mat-dialog-close></span>
    </div>
  </div>
  <mat-dialog-content>
    <div class="edit-custom-fields-modal">
      <ts-toggle-buttons-group
        *ngIf="menu.length>0"
        [items]="menu"
        [selectedId]="selectedEntity"
        (selectItem)="selectEntity($event)"
      />


      <div class="annotation-container">
        <span class="ts-icon ts-icon-actions-info-circle text-2lg"></span>
        После сохранения эта настройка меняет доп. поля во всех существующих товарах, услугах, комплектах
      </div>

      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <div cdkDropList (cdkDropListDropped)="onDrop($event)" formArrayName="fields">
          <div class="my-3" *ngFor="let group of fieldsFormArray?.controls; let i = index">
            <div cdkDrag [formArrayName]="i" class="edit-custom-fields-modal__controls">
              <i class="ts-icon ts-icon-menu-Move text-2lg ts-drag-handle" cdkDragHandle></i>

              <ts-select
                label="Тип данных"
                formControlName="type"
                [stringify]="recordStringify"
                [hasCleaner]="false"
              >
                <button *ngFor="let val of customFieldTypesOptions" class="dropdown-item" [tsSelectOption]="val.id">
                  {{ val.name }}
                </button>
              </ts-select>

              <div>
                <app-title title="Наименования поля" formControlName="name" tsFocusable/>
                <small class="text-danger" *ngIf="group.get('name')?.hasError('duplicated')">
                  Наименование полей дублируется
                </small>
              </div>

              <ts-role-access-wrapper [requiredRole]="deleteItemRole(i)">
                <button
                  type="button"
                  class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon ts-text-icon-accent-dim-color mt-2"
                  (click)="deleteField(i)"
                >
                  <span class="ts-icon ts-icon-actions-Trash"></span>
                </button>
              </ts-role-access-wrapper>
            </div>
          </div>
        </div>
      </ts-role-access-wrapper>

      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <div (click)="addField()" class="ts-text-control-primary cursor-pointer d-flex gap-1 align-items-center">
          <span class="ts-icon ts-icon-actions-Add text-2lg"></span>
          <span class="ts-caption-strong">Добавить поле</span>
        </div>
      </ts-role-access-wrapper>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="d-flex flex-column gap-2 w-100">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <button class="ts-btn primary ts-btn-filled ts-btn-md w-100">Сохранить</button>
      </ts-role-access-wrapper>
      <button class="ts-btn btn-grey ts-btn-bezeled ts-btn-md w-100" type="button" (click)="close()">
        Отмена
      </button>
    </div>
  </mat-dialog-actions>

</form>

