import { ROLES } from '@topseller/core';

export type AppLink = {
  url: string;
  name: string;
  match: string;
  production: boolean;
  requiredRole?: string;
};

export const appsLinks: AppLink[] = [
  {
    url: '/topsellerid',
    name: 'Topseller ID',
    match: 'topsellerid',
    production: true,
  },
  {
    url: '/hub',
    name: 'Hub',
    match: 'hub',
    production: true,
  },
  {
    url: '/connect',
    name: 'Connect',
    match: 'connect',
    production: true,
    requiredRole: ROLES.ROLE_ACCOUNT_APPLICATION_VIEW,
  },
  {
    url: '/analytics',
    name: 'Analytics',
    match: 'analytics',
    production: false,
  },
  {
    url: '/wb-ads',
    name: 'Ads',
    match: 'wb-ads',
    production: true,
  },
  {
    url: '/repricer',
    name: 'Repricer',
    match: 'repricer',
    production: true,
  },
  {
    url: '/dropship',
    name: 'Dropship',
    match: 'dropship',
    production: true,
  },
  {
    url: '/ai',
    name: 'AiTools',
    match: 'ai',
    production: true,
  },
  {
    url: '/pim',
    name: 'Pim',
    match: 'pim',
    production: true,
    requiredRole: ROLES.ROLE_ACCOUNT_APPLICATION_VIEW,
  },
];
