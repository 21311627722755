<ts-dropdown [content]="dropdownTemplate" [isVisible]="(showModal$ | async)!" (changeVisible)="onChangeVisible($event)">
  <button type="button" class="nav-icon-button-with-text">
    <span class="ts-icon ts-icon-actions-Question"></span>
    <div class="nav-icon-button-with-text__title">Помощь</div>
  </button>
</ts-dropdown>

<ng-template #dropdownTemplate>
  <div class="key-info-dropdown">
    <div class="header-wrapper">
      <div class="header-title">
        <span>Онлайн-поддержка</span>
        <img src="/assets/img/hub/key-info/cross.svg" class="header-title-img" alt="cross" height="22" width="22"
          (click)="closeDropDown()" />
      </div>
      <p class="header-text">Ответы на вопросы вы можете получить в разделе "База знаний" или "Онлайн-чат".
        Если вам требуется дополнительная информация, обучение, вы можете записаться на онлайн презентацию,
        а менеджеры отдела продаж расскажут о сервисах Экосистемы Topseller.</p>
    </div>
    <div class="main-item" (click)="openChat()">
      <img class="img" src="/assets/img/hub/key-info/online-chat.svg" alt="online-chat" height="35" width="35" />
      <div>
        <p class="item-title">
          Онлайн чат
        </p>
        <p class="item-text">
          Связаться с техподдержкой можно с 09.00 до 03.00 ежедневно, в чате или по номеру тел: +7(800)600-79-87
        </p>
      </div>
    </div>
    <div class="main-item" (click)="openUrl('help.topseller.ru')">
      <img class="img" src="/assets/img/hub/key-info/base-knowledges.svg" alt="base-knowledges" height="35"
        width="35" />
      <div>
        <p class="item-title">
          База знаний
        </p>
        <p class="item-text">
          Подробная информация по обновлениям сервисов, с детальной инструкцией настроек
        </p>
      </div>
    </div>

    <div class="main-item" (click)="openUrl('youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ')">
      <img class="img" src="/assets/img/hub/key-info/video-block.svg" alt="video-block" height="35" width="35" />
      <div>
        <p class="item-title">
          Видеоблог
        </p>
        <p class="item-text">
          Записи вебинаров, обучающие видео инструкции по всем сервисам
          экосистемы Topseller
        </p>
      </div>
    </div>

    <div class="main-item" (click)="openUrl('widget.easyweek.io/topseller/9564')">
      <img class="img" src="/assets/img/hub/key-info/video-presentation.svg" alt="video-presentation" height="35"
        width="35" />
      <div>
        <p class="item-title">
          Записаться на презентацию
        </p>
        <p class="item-text">
          В удобное для вас время покажем функционал по каждому продукту, который вас заинтересовал
        </p>
      </div>
    </div>
    <div class="footer-wrapper">
      <div class="footer">
        <ts-checkbox (toggle)="toggleShowHelpOnStart($event)" [active]="(showHelpOnStart$ | async)!"></ts-checkbox>
        <span class="footer-checkbox-text">Показывать при старте</span>
      </div>
    </div>
  </div>
</ng-template>
