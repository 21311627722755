import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[tsFocusable]',
    standalone: true,
})
export class FocusableDirective implements AfterViewInit {
    constructor(private host: ElementRef) {
    }

    ngAfterViewInit() {
      const firstInput = this.host.nativeElement.querySelector('input');

      if (firstInput) {
        firstInput.focus();
      }
    }
}
