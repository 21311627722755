import {catchError, Observable, of, retryWhen, throwError, timer} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";

export interface RetryConfig {
  maxRetryAttempts: number;
  retryDelay: number;
  includedCodes: number[];
}

const defaultRetryConfig: RetryConfig = {
  maxRetryAttempts: 10,
  retryDelay: 1000,
  includedCodes: [202]
};

/**
 * Оператор, который можно встраивать в пайпу RxJS для повторного выполнения запросов, которые вернули 202 код
 * Для того, чтобы это работало, 202 надо кинуть как ошибку, которая обработается в retryWhen с помощью genericRetryStrategy
 * */
export function handleRetryOn202(config: Partial<RetryConfig> = {}) {
  const finalConfig: RetryConfig = { ...defaultRetryConfig, ...config };
  return (source: Observable<HttpResponse<any>>) => source.pipe(
    mergeMap(response =>
      response.status === 202
        ? throwError(
          () =>
            new HttpErrorResponse({
              status: 202,
              statusText: 'not ready',
            })
        )
        : of(response.body)
    ),
    retryWhen(genericRetryStrategy(finalConfig)),
  );
}


/**
 * Функция, которая позволяет повторить выполние Observable
 * По умолчанию будет 10 попыток раз в секунду. Повтор произойдет если HttpErrorResponse имеет статус 202
 */
export const genericRetryStrategy = ({ maxRetryAttempts, retryDelay, includedCodes }: RetryConfig) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (
        retryAttempt <= maxRetryAttempts &&
        includedCodes.find(e => e === error.status)
      ) {
        return timer(retryDelay);
      }
      return throwError(error);
    }),
  );
};
