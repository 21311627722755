<div class="edit-page-controls">
  <div class="buttons-group">
    <a
      type="button"
      class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon ts-controls-bar-item"
      [routerLink]="host.backLinkUrl"
      [state]="host.backLinkState"
    >
      <span class="ts-icon ts-icon-arrow-arrow-left"></span>
    </a>

    <!-- create dropdown start -->
    <ts-dropdown
      [content]="createActions"
      *ngIf="host.isEdit && host.documentCreationLinks.length && isShowAddBtn"
    >
      <button
        type="button"
        class="ts-btn primary ts-btn-filled ts-btn-md ts-btn-icon ts-controls-bar-item"
      >
        <span class="ts-icon ts-icon-actions-Add"></span>
        Документ
      </button>
    </ts-dropdown>
    <ng-template #createActions>
      <ts-role-access-wrapper *ngFor="let action of host.documentCreationLinks" [requiredRole]="action.requiredRole">
        <button
          class="dropdown-item"
          type="button"
          dropdownOption
          (click)="onClick(action.url, host.getParentId)"
        >
          {{ action.title }}
        </button>
      </ts-role-access-wrapper>
    </ng-template>
    <!-- create dropdown end -->

    <ts-actions-dropdown
      [actions]="host.documentActionsList"
      *ngIf="host.id"
    />
  </div>
  <div class="buttons-group buttons-group__right">
    <ts-role-access-wrapper [requiredRole]="host.documentEditRole">
      <button
        type="submit"
        class="ts-btn primary ts-btn-filled ts-btn-md ts-controls-bar-item"
      >
        {{ host.isTrashed ? 'Восстановить' : 'Сохранить' }}
      </button>
    </ts-role-access-wrapper>
    <a
      type="button"
      class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-controls-bar-item"
      [routerLink]="host.backLinkUrl"
    >Отмена</a
    >
  </div>
</div>
