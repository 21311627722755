import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first, Subscription, take } from 'rxjs';
import { ContactPersonBaseService } from '../../../common/services/contactPerson.service';

import { BaseInnItemComponent } from '../../../common/components/base-inn-item.component';
import { IdentityMatcher, StringHandler } from '../../../common/types';
import { ContactPerson, CounterpartyService, OrganizationService, } from '../../../data';
import {
  ConfirmDialogComponent,
  Counterparty,
  DialogService,
  Record,
  ROLES,
  setShowLoader,
  SettlementAccount
} from '@topseller/core';
import { LegalFormsEnum } from '@topseller/common/legal-form';
import { SettlementBaseService } from '../../../common/services/settlement.service';
import { LegalFormPipe } from '@topseller/common/pipe/legal-form.pipe';
import { Store } from "@ngrx/store";

@Component({
  selector: 'ts-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['../company/company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SettlementBaseService, LegalFormPipe, ContactPersonBaseService,
    {provide: BaseInnItemComponent, useExisting: PartnerComponent}
  ],
})
export class PartnerComponent
  extends BaseInnItemComponent
  implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({
    id: new FormControl<string | undefined>(undefined),
    email: new FormControl<string>('', [Validators.email]),
    fullName: new FormControl<string>('', [Validators.maxLength(255)]),
    inn: new FormControl<string>('', [
      Validators.minLength(10),
      Validators.maxLength(12),
    ]),
    kpp: new FormControl<string>('', [
      Validators.minLength(9),
      Validators.maxLength(9),
    ]),
    legalAddress: new FormControl<string>('', [Validators.maxLength(255)]),
    groups: new FormControl<string>(''),
    legalForm: new FormControl(this.legalFormObject[0]),
    actualAddress: new FormControl(''),
    name: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    ogrn: new FormControl<string>(''),
    okpo: new FormControl<string>(''),
    phone: new FormControl<string>(''),
    settlementAccounts: this.settlementService.settlementAccounts,
    contactPersons: this.contactService.contactPersons,
  });

  @Input() isDefault = false;

  showHeaderBtnsSub$?: Subscription;
  showHeaderBtns = false;
  public editItemRole = ROLES.ROLE_COUNTERPARTY_EDIT;
  public deleteItemRole = ROLES.ROLE_COUNTERPARTY_DELETE;
  override backLinkUrl = '/hub/handbooks/partners';
  private formChanges$?: Subscription;

  constructor(
    private counterpartyService: CounterpartyService,
    private legalFormPipe: LegalFormPipe,
    organizationService: OrganizationService,
    public readonly settlementService: SettlementBaseService,
    public readonly contactService: ContactPersonBaseService,
    activatedRoute: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    changeDetectorRef: ChangeDetectorRef,
    matDialog: MatDialog,
    store: Store,
    @Optional() private dialogRef: MatDialogRef<PartnerComponent>,
    private dialogService: DialogService,
  ) {
    super(
      activatedRoute,
      toastr,
      router,
      changeDetectorRef,
      matDialog,
      store,
      organizationService
    );
  }

  get email() {
    return this.form.get('email');
  }

  get inn() {
    return this.form.get('inn');
  }

  get phone() {
    return this.form.get('phone');
  }

  get name() {
    return this.form.get('name');
  }

  get okpo() {
    return this.form.get('okpo');
  }

  get kpp() {
    return this.form.get('kpp');
  }

  get ogrn() {
    return this.form.get('ogrn');
  }

  public get isModal(): boolean {
    return this.dialogRef?.componentInstance instanceof PartnerComponent;
  }

  public legalFormStringify = (legalForm: LegalFormsEnum) =>
    this.legalFormPipe.transform(legalForm);

  public hasError(fieldName: string) {
    const formField = this.form.get(fieldName);
    return formField && formField.touched && formField.invalid;
  }

  ngOnInit(): void {
    this.getResolvedData(
      this.form,
      'partner',
      this.accountsHandler,
      this.contactHandler
    );
    this.showHeaderBtnsSub$ = this.showHeaderBtns$.subscribe(
      (show) => (this.showHeaderBtns = show)
    );
  }

  // Counterparty CRUD Operations

  public save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastr.error('Форма содержит ошибки');
      return;
    }
    const counterparty = this.form.value;
    this.removeEmptyBanks(counterparty);
    this.store.dispatch(setShowLoader({showLoader: true}))
    if (counterparty.id === null || counterparty.id === undefined) {
      this.createCounterparty(counterparty);
    } else {
      this.updateCounterparty(counterparty);
    }
  }

  removeEmptyBanks(counterparty: Counterparty) {
    counterparty.settlementAccounts?.forEach(account => {
      if (!account.bank?.id) {
        account.bank = null;
      }
    })
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public deleteCounterparty(): void {
    const data = {
      title: 'Удаление контрагента',
      content: 'Вы уверены, что хотите удалить контрагента ?',
      okBtn: 'Удалить'
    };
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialogService.openConfirmDialog(data);

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (result: boolean) => {
          this.deleteItem(
            this.counterpartyService,
            'deleteAppCounterpartyDelete',
            '/hub/handbooks/partners',
            result ? 'да' : 'нет'
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.showHeaderBtnsSub$?.unsubscribe();
    this.formChanges$?.unsubscribe();
  }

  public recordIdentityMatcher: IdentityMatcher<Record> = (
    {id: previousId},
    {id: nextId}
  ) => previousId === nextId;

  public recordStringify: StringHandler<Record> = (item) => item.name;

  private createCounterparty(counterparty: Counterparty): void {
    this.counterpartyService
      .postAppCounterpartyCreate(counterparty)
      .pipe(take(1))
      .subscribe({
        next: (result: Counterparty) => {
          this.toastr.success('Контрагент успешно создан');
          this.store.dispatch(setShowLoader({showLoader: false}))
          if (this.isModal) {
            this.dialogRef.close(result);
          } else {
            this.form.markAsPristine();
            this.router.navigateByUrl(`hub/handbooks/partners/${result.id}`);
          }
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private updateCounterparty(counterparty: Counterparty): void {
    this.counterpartyService
      .patchAppCounterpartyUpdate(counterparty)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastr.success('Контрагент успешно сохранен');
          this.store.dispatch(setShowLoader({showLoader: false}))
          this.form.markAsPristine();
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private accountsHandler = (settlementAccounts: SettlementAccount[]) =>
    this.settlementService.setSettlementAccounts(settlementAccounts);

  private contactHandler = (contactPersons: ContactPerson[]) =>
    this.contactService.setContactPersons(contactPersons);
}
