<form [formGroup]="form" style="width: 100%">
  <app-title
    [formControlName]="field.identifier!"
    [title]="field.title!"
    [readonly]="(!isNew && !isEdit) || (field.isBlocked! && isEdit)"
  ></app-title>
</form>
<span
  *ngIf="error"
  class="ts-icon text-normal ts-icon-actions-info-circle icon-properties"
  [tsHint]="hintMasterPhrase"
></span>
<ng-template #hintMasterPhrase>
    <span style="font-size: 12px; line-height: 12px; color: #E56B6B;">
      {{ error![0] }}
    </span>
</ng-template>
