<form [formGroup]="form" (ngSubmit)="save()" class="ts-page__content d-flex flex-column gap-3">
  <ts-handbook-item-control-bar [handbooksSettings]="'Настройки контрагента'" [editItemRole]="editItemRole"/>

  <ts-form-controls-card class="mt-2" label="Общее" [isExpandable]="false">
    <div slot="card-actions">
      <ts-role-access-wrapper *ngIf="showHeaderBtns$ | async" [requiredRole]="deleteItemRole">
        <button type="button" class="ts-btn error ts-btn-bezeled ts-btn-md"
                (click)="deleteCounterparty()">
          <span class="ts-icon ts-icon-actions-Trash"></span>
          <span class="">Удалить контрагента</span>
        </button>
      </ts-role-access-wrapper>
    </div>

    <ts-role-access-wrapper [requiredRole]="editItemRole">
      <div class="form-controls-card__content ts-general-controls-grid">
        <ts-select
          [hasCleaner]="false"
          formControlName="legalForm"
          label="Тип контрагента"
          [stringify]="legalFormStringify"
        >
          <button
            [tsSelectOption]="item"
            class="dropdown-item"
            *ngFor="let item of legalFormObject"
            (click)="onCounterpartySelectorChange(form)"
          >
            {{ item | legalForm }}
          </button>
        </ts-select>

        <app-title
          formControlName="fullName"
          title="Полное наименование"
          placeholder="OOO <Полное название>"
        />

        <div class="company-info__fill-wrap">
          <app-title
            formControlName="inn"
            title="Заполнить по ИНН"
            placeholder="1234567890"
            [maskTemplate]="onlyNumberTemplate"
            trailingIcon="ts-icon-document-edit"
            [trailingIconClickable]="true"
            (trailingIconClick)="fillByInn(form)"
          />
          <span
            class="input-error"
            *ngIf="inn && inn.touched && inn.dirty && inn.invalid"
          >некоректный ИНН</span>
        </div>

        <app-title
          formControlName="phone"
          title="Телефон"
          placeholder="+7(999)999-99-99"
        />

        <app-title
          formControlName="email"
          title="E-mail"
          placeholder=" example@topseller.ru"
        />

        <app-title
          formControlName="legalAddress"
          title="Юридический адрес"
          placeholder="Н-р: Москва, ..."
        />

        <app-title
          formControlName="actualAddress"
          title="Фактический адрес"
          placeholder="Москва, ..."
        />

        <app-title
          formControlName="kpp"
          title="КПП"
          placeholder="Н-р: 123456789"
          [maskTemplate]="onlyNumberTemplate"
        />

        <app-title
          formControlName="okpo"
          title="ОКПО"
          placeholder="Н-р: 12345678901234"
          [maskTemplate]="onlyNumberTemplate"
        />

        <app-title
          formControlName="ogrn"
          title="ОГРН"
          placeholder="Н-р: 12345678901234"
          [maskTemplate]="onlyNumberTemplate"
        />

      </div>
    </ts-role-access-wrapper>
  </ts-form-controls-card>

  <!--  Contacts -->
  <ts-form-controls-card label="Контакты" [isExpandable]="true">
    <div slot="card-actions">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <button
          type="button"
          class="ts-btn secondary ts-btn-filled ts-btn-md ts-btn-icon ts-controls-bar-item"
          (click)="contactService.addContact()"
        >
          <span class="ts-icon ts-icon-actions-Add"></span>
          Контактное лицо
        </button>
      </ts-role-access-wrapper>
    </div>

    <div class="form-controls-card__content">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <ts-handbook-card-partner
          (deleteCard)="contactService.removeContact($event)"
          [partnerFormGroups]="contactControls"
        />
      </ts-role-access-wrapper>
    </div>

  </ts-form-controls-card>

  <!--  Bank accounts-->
  <ts-form-controls-card label="Банковский счет" [isExpandable]="true">
    <div slot="card-actions">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <button
          type="button"
          class="ts-btn secondary ts-btn-filled ts-btn-md ts-btn-icon ts-controls-bar-item"
          (click)="settlementService.addAccount()"
        >
          <span class="ts-icon ts-icon-actions-Add"></span>
          Счет
        </button>
      </ts-role-access-wrapper>
    </div>

    <div class="form-controls-card__content">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <ts-handbook-edit-bank-account
          (deleteAccount)="settlementService.removeAccount($event)"
          [bankAccountGroups]="accountControls"
          (changed)="settlementService.settlementToggleHandler($event)"
          (fillByBik)="settlementService.fillByBik($event)"
        />
      </ts-role-access-wrapper>
    </div>

  </ts-form-controls-card>
</form>

<!-- Confirm Dialogs -->

<app-confirm-dialog
  *ngIf="showDialog"
  (selection)="onDeactivateSelection($event)"
></app-confirm-dialog>

<!-- Loading On Creation After Guard -->
<div class="state-loading" *ngIf="(creationState$ | async) === 'loading'">
  <ts-dot-loader [style.transform]="'scale(' + 3 + ')'"></ts-dot-loader>
</div>
