<form [formGroup]="form" (ngSubmit)="!showHeaderBtns ? createWarehouse() : updateWarehouse()"
      class="d-flex flex-column gap-3 ts-page__content">
  <ts-handbook-item-control-bar [handbooksSettings]="'Склад'" [main]="true" [editItemRole]="editItemRole">
    <app-toggle-switch
      formControlName="isDefault"
      label="Сделать основным"
      slot="bar-content"
    />
  </ts-handbook-item-control-bar>

  <ts-form-controls-card label="Общее" [isExpandable]="false">
    <div slot="card-actions">
      <ts-role-access-wrapper [requiredRole]="deleteItemRole">
        <button *ngIf="showHeaderBtns$ | async" type="button" class="ts-btn error ts-btn-bezeled ts-btn-md"
                (click)="deleteWarehouse()">
          <span class="ts-icon ts-icon-actions-Trash"></span>
          <span class="">Удалить склад</span>
        </button>
      </ts-role-access-wrapper>
    </div>

    <div class="form-controls-card__content">
      <ts-role-access-wrapper [requiredRole]="editItemRole">
        <div class="ts-general-controls-grid">
          <ts-select
            label="Группа складов"
            [identityMatcher]="recordIdentityMatcher"
            [stringify]="recordStringify"
            [hasCleaner]="true"
            formControlName="warehouseGroup"
          >
            <div *ngIf="warehouseGroups$ | async as warehouseGroups">
              <button
                class="dropdown-item"
                *ngFor="let warehouseGroup of warehouseGroups"
                [tsSelectOption]="warehouseGroup"
              >
                {{ warehouseGroup.name }}
              </button>
            </div>
          </ts-select>

          <app-title formControlName="address" title="Адрес"/>
        </div>

        <div class="col-9 col-xl-12">
          <app-title formControlName="note" title="Примечание" [multirow]="true"/>
        </div>
      </ts-role-access-wrapper>
    </div>
  </ts-form-controls-card>
</form>
<!-- Confirm Dialogs -->

<app-confirm-dialog
  *ngIf="showDialog"
  (selection)="onDeactivateSelection($event)"
></app-confirm-dialog>

<!-- Loading On Creation After Guard -->
<div class="state-loading" *ngIf="(creationState$ | async) === 'loading'">
  <ts-dot-loader [style.transform]="'scale(' + 3 + ')'"></ts-dot-loader>
</div>
