import { FormGroup } from '@angular/forms';

export abstract class FormProvider {
  protected _isLoading = false;

  public get isLoading() {
    return this._isLoading;
  }

  public set isLoading(value: boolean) {
    this._isLoading = value;
  }

  abstract getForm(): FormGroup;
}
