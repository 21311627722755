<div class="product-cell">
  <img [ngSrc]='product.image' width="30" height="30" alt="Product Image" class="product-image" *ngIf="product.image">
  <span class="ts-icon ts-icon-goods-box product-cell__icon" *ngIf="!product.image"></span>
  <div class="d-flex flex-column justify-content-center h-100">
    <div *ngIf="product.type" class="product-cell__type">{{product.type|tsProductType}}</div>
    <div class="product-cell__name">
      {{product.name}}
    </div>
  </div>

  <button
    *ngIf="showButtonOpenProduct"
    type="button"
    class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon ts-controls-bar-item product-cell__open"
    (click)="openProductClick()"
  >
    <span class="ts-icon ts-icon-arrow-export-4"></span>
  </button>
</div>
