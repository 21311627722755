import { Component, Input } from '@angular/core';
import { DocumentEditBase } from '../../document-edit-base';
import { HubDocument } from '../../../data';
import { Router } from '@angular/router';

@Component({
  selector: 'ts-item-controls-bar',
  templateUrl: './item-controls-bar.component.html',
  styleUrls: ['./item-controls-bar.component.scss'],
})
export class ItemControlsBarComponent<T extends HubDocument> {
  @Input() isShowAddBtn = true;

  constructor(
    public host: DocumentEditBase<T>,
    private readonly router: Router
  ) {}

  public onClick(entity: any, id: string): void {
    this.router.navigate([entity, id], {
      state: { referrer: this.router.url },
    });
  }
}
